import React from 'react';

const ContentEcommerce = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.5267 15.5988C35.4198 15.6034 35.3134 15.5858 35.2137 15.5471C35.114 15.5084 35.0235 15.4493 34.9479 15.3739L23.0127 3.46604L11.2068 15.2458C11.052 15.3617 10.8604 15.4178 10.6673 15.4041C10.4742 15.3904 10.2925 15.3079 10.1556 15.1714C10.0187 15.0348 9.93566 14.8535 9.92194 14.6608C9.90822 14.4682 9.96483 14.277 10.081 14.1225L22.4339 1.7648C22.5089 1.68714 22.5987 1.62559 22.6982 1.5834C22.7977 1.5412 22.9046 1.51953 23.0127 1.51953C23.1208 1.51953 23.2278 1.5412 23.3273 1.5834C23.4268 1.62559 23.5169 1.68714 23.592 1.7648L36.0737 14.2185C36.1515 14.2933 36.2132 14.3833 36.2555 14.4825C36.2978 14.5818 36.3199 14.6885 36.3199 14.7964C36.3199 14.9043 36.2978 15.011 36.2555 15.1102C36.2132 15.2095 36.1515 15.2991 36.0737 15.3739C36.0029 15.4469 35.9177 15.5045 35.8236 15.5432C35.7295 15.5818 35.6284 15.6009 35.5267 15.5988Z"
      fill="#475467"
    />
    <path
      d="M15.9551 15.6003C15.7524 15.6025 15.5566 15.5146 15.4086 15.3553C15.2742 15.1822 15.2 14.9583 15.2 14.7261C15.2 14.4939 15.2742 14.2696 15.4086 14.0965L18.4438 10.5999C18.5137 10.5131 18.5981 10.4435 18.6921 10.3954C18.786 10.3474 18.8876 10.3219 18.9903 10.3203C19.0886 10.3225 19.1852 10.3485 19.2741 10.3967C19.3631 10.4449 19.442 10.5141 19.5061 10.5999C20.2626 11.4722 21.289 11.9628 22.3594 11.9636C22.8778 11.9643 23.3912 11.8476 23.8704 11.6196C24.3495 11.3916 24.7851 11.0568 25.152 10.6349C25.2543 10.4979 25.3907 10.4009 25.5429 10.357C25.6951 10.3132 25.8553 10.3249 26.0016 10.3903C26.1128 10.4389 26.2158 10.5098 26.3054 10.5999L29.128 13.887C29.2376 14.0553 29.291 14.2635 29.278 14.4734C29.2651 14.6833 29.1867 14.8808 29.0576 15.0296C28.9284 15.1784 28.757 15.2682 28.5748 15.2831C28.3926 15.298 28.2118 15.2369 28.0657 15.1107L25.6981 12.4182C24.7356 13.2522 23.5638 13.7034 22.3594 13.7034C21.155 13.7034 19.9833 13.2522 19.0207 12.4182L16.562 15.2507C16.4897 15.3573 16.398 15.4437 16.293 15.5042C16.188 15.5647 16.0725 15.5977 15.9551 15.6003Z"
      fill="#475467"
    />
    <path
      d="M42.5618 45.5177H8.43504C6.96182 45.5177 5.5486 44.9533 4.50688 43.9489C3.46515 42.9444 2.87988 41.5818 2.87988 40.1613V16.3672C2.87988 16.1631 2.96403 15.9674 3.1137 15.8231C3.26338 15.6788 3.46632 15.5977 3.67799 15.5977H42.5618C42.7734 15.5977 42.9764 15.6788 43.1261 15.8231C43.2757 15.9674 43.3599 16.1631 43.3599 16.3672V25.9094C43.3599 26.1135 43.2757 26.3096 43.1261 26.4539C42.9764 26.5982 42.7734 26.679 42.5618 26.679C42.3501 26.679 42.1472 26.5982 41.9975 26.4539C41.8478 26.3096 41.7637 26.1135 41.7637 25.9094V17.1367H4.47609V40.1613C4.47184 40.6637 4.57132 41.1622 4.76875 41.6271C4.96619 42.0921 5.25765 42.5145 5.62609 42.8697C5.99453 43.225 6.43263 43.506 6.91483 43.6964C7.39703 43.8868 7.91401 43.9827 8.43504 43.9786H41.7637V35.2363C41.7637 35.0322 41.8478 34.8366 41.9975 34.6922C42.1472 34.5479 42.3501 34.4668 42.5618 34.4668C42.7734 34.4668 42.9764 34.5479 43.1261 34.6922C43.2757 34.8366 43.3599 35.0322 43.3599 35.2363V44.7481C43.3599 44.9522 43.2757 45.1479 43.1261 45.2922C42.9764 45.4365 42.7734 45.5177 42.5618 45.5177Z"
      fill="#475467"
    />
    <path
      d="M38.8174 17.328H3.6785C3.46669 17.328 3.26362 17.2501 3.11385 17.1114C2.96408 16.9728 2.87988 16.7848 2.87988 16.5888V13.632C2.87988 12.7536 3.25671 11.9116 3.92767 11.2905C4.59864 10.6695 5.50876 10.3203 6.45765 10.3203H10.7381C10.9499 10.3203 11.1534 10.3982 11.3031 10.5369C11.4529 10.6755 11.5367 10.8635 11.5367 11.0595C11.5367 11.2556 11.4529 11.4435 11.3031 11.5822C11.1534 11.7208 10.9499 11.7987 10.7381 11.7987H6.45765C5.93237 11.7987 5.42878 11.992 5.05735 12.3358C4.68592 12.6796 4.47711 13.1458 4.47711 13.632V15.9976H38.1467V11.7987H34.9523C34.7405 11.7987 34.5374 11.7208 34.3876 11.5822C34.2379 11.4435 34.1537 11.2556 34.1537 11.0595C34.1537 10.8635 34.2379 10.6755 34.3876 10.5369C34.5374 10.3982 34.7405 10.3203 34.9523 10.3203H39.0413C39.2531 10.3203 39.4561 10.3982 39.6059 10.5369C39.7557 10.6755 39.8399 10.8635 39.8399 11.0595V16.7368C39.822 16.8412 39.7801 16.9409 39.717 17.0291C39.654 17.1173 39.571 17.1918 39.4741 17.2479C39.3772 17.3039 39.2684 17.3402 39.1551 17.354C39.0418 17.3678 38.9267 17.3589 38.8174 17.328Z"
      fill="#475467"
    />
    <path
      d="M41.8988 36.7184H32.4475C31.1076 36.6014 29.8552 35.9014 28.9414 34.7591C28.0276 33.6168 27.5198 32.1165 27.5198 30.5584C27.5198 29.0004 28.0276 27.5001 28.9414 26.3578C29.8552 25.2154 31.1076 24.5155 32.4475 24.3984H41.8988C42.753 24.3984 43.5725 24.7922 44.1766 25.4925C44.7806 26.1928 45.1198 27.1426 45.1198 28.133V32.9843C45.1198 33.9747 44.7806 34.9245 44.1766 35.6248C43.5725 36.3251 42.753 36.7184 41.8988 36.7184ZM32.4475 26.2483C31.4961 26.3464 30.6106 26.8541 29.9658 27.6712C29.321 28.4883 28.9634 29.5557 28.9634 30.6632C28.9634 31.7708 29.321 32.8382 29.9658 33.6553C30.6106 34.4724 31.4961 34.98 32.4475 35.0782H41.8988C42.3538 35.0782 42.7903 34.8685 43.1121 34.4954C43.4338 34.1223 43.6147 33.6162 43.6147 33.0886V28.2374C43.6188 27.9749 43.5772 27.7141 43.4924 27.4706C43.4076 27.2271 43.2814 27.0061 43.1213 26.8204C42.9611 26.6348 42.7702 26.4884 42.5602 26.3901C42.3502 26.2918 42.1252 26.2435 41.8988 26.2483H32.4475Z"
      fill="#475467"
    />
    <path
      d="M31.948 33.1978C31.4246 33.2034 30.9115 33.0532 30.4737 32.7665C30.0359 32.4799 29.6928 32.0694 29.4887 31.5876C29.2846 31.1057 29.2285 30.5742 29.3272 30.0603C29.4259 29.5463 29.675 29.0733 30.0431 28.7013C30.4112 28.3293 30.8814 28.0749 31.3943 27.9707C31.9071 27.8666 32.4393 27.9175 32.9234 28.1164C33.4074 28.3154 33.8217 28.6536 34.1131 29.0883C34.4044 29.5231 34.5598 30.0344 34.5598 30.5577C34.5598 31.253 34.2857 31.9205 33.7966 32.4148C33.3075 32.9091 32.6433 33.1904 31.948 33.1978ZM31.948 29.3503C31.625 29.3502 31.3149 29.4763 31.084 29.702C30.853 29.9277 30.7193 30.2349 30.712 30.5577C30.712 30.8854 30.8423 31.1998 31.074 31.4316C31.3058 31.6633 31.6202 31.7936 31.948 31.7936C32.2708 31.7863 32.578 31.6529 32.8037 31.422C33.0294 31.191 33.1556 30.8806 33.1555 30.5577C33.1555 30.3991 33.1243 30.2421 33.0636 30.0956C33.0029 29.949 32.9141 29.8162 32.802 29.7041C32.6898 29.5919 32.5566 29.5028 32.4101 29.4421C32.2636 29.3814 32.1066 29.3503 31.948 29.3503Z"
      fill="#475467"
    />
  </svg>
);

export default ContentEcommerce;
